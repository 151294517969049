@charset "ISO-8859-1";

html {
  scroll-behavior: smooth !important;
}
a {
  text-decoration: none !important;
  cursor: pointer !important;
}

@media (max-width: 900px) {
  .menu-icon {
    display: none !important;
  }
}

.set-background-image {
  background-image: url(../images/mapimage.jpg) !important;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 160px !important;
  border-radius: 3px !important;
}

.support {
  position: absolute !important;
  bottom: 0 !important;
  justify-content: center !important;
  align-items: center !important;
  width: 95% !important;
}

.site-text {
  font-family: "Montserrat Alternates", sans-serif !important;
}
.landing-text {
  font-family: "Fredericka the Great", cursive !important;
}

.sub-text {
  font-family: "Black Ops One", cursive;
}

.empire-market-font-1 {
  font-family: "Lilita One", cursive !important;
}

.empire-market-font-2 {
  font-family: "Monoton", cursive !important;
}

.empire-market-font-3 {
  font-family: "Black Han Sans", sans-serif !important;
}

.other-text {
  font-family: "Noto Sans", "Montserrat", sans-serif !important;
}

.sample-text {
  font-family: "Noto Sans", "Montserrat", sans-serif !important;
}
.gradient-one {
  background: rgb(0, 36, 30);
  background: linear-gradient(
    90deg,
    rgba(0, 36, 30, 1) 0%,
    rgba(9, 86, 121, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  );
}

.gradient-two {
  background: rgb(255, 214, 0);
  background: linear-gradient(
    90deg,
    rgba(255, 214, 0, 1) 0%,
    rgba(255, 145, 0, 0.9444152661064426) 0%,
    rgba(255, 214, 0, 1) 100%
  );
}

.gradient-three {
  background: rgb(0, 36, 30);
  background: linear-gradient(
    90deg,
    rgba(0, 36, 30, 1) 0%,
    rgba(9, 86, 121, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  );
}

.gradient-four {
  background: rgb(0, 36, 30);
  background: linear-gradient(
    90deg,
    rgba(0, 36, 30, 1) 0%,
    rgba(9, 86, 121, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  );
}

.dash-card {
  box-shadow: 10px !important;
}

.box-button:hover {
  background-color: rgb(217, 217, 217) !important;
}

.custom-box-shadow {
  box-shadow: 1px 1px 87px -46px rgba(115, 115, 115, 0.79) !important;
  -webkit-box-shadow: 1px 1px 87px -46px rgba(103, 100, 100, 0.79) !important;
  -moz-box-shadow: 1px 1px 87px -46px rgba(113, 109, 109, 0.79) !important;
}

.card-box-shadow-empire {
  box-shadow: -14px 15px 42px -8px rgba(0, 186, 196, 0.79) !important;
  -webkit-box-shadow: -14px 15px 42px -8px -18px rgba(0, 186, 196, 0.79) !important;
  -moz-box-shadow: -14px 15px 42px -8px rgba(0, 186, 196, 0.79) !important;
}

.card-box-shadow-shop {
  box-shadow: -14px 15px 42px -8px rgba(255, 135, 16, 0.79) !important;
  -webkit-box-shadow: -14px 15px 42px -8px -18px rgba(242, 150, 20, 0.79) !important;
  -moz-box-shadow: -14px 15px 42px -8px rgba(215, 116, 9, 0.79) !important;
}

.card-box-shadow-properties {
  box-shadow: -14px 15px 42px -8px rgba(83, 8, 89, 0.79) !important;
  -webkit-box-shadow: -14px 15px 42px -8px -18px rgba(64, 8, 67, 0.79) !important;
  -moz-box-shadow: -14px 15px 42px -8px rgba(70, 12, 69, 0.79) !important;
}

.card-box-shadow-car {
  box-shadow: -14px 15px 42px -8px rgba(74, 195, 26, 0.79) !important;
  -webkit-box-shadow: -14px 15px 42px -8px -18px rgba(115, 192, 21, 0.79) !important;
  -moz-box-shadow: -14px 15px 42px -8px rgba(85, 209, 40, 0.79) !important;
}

.card-box-shadow-blog {
  box-shadow: -14px 15px 42px -8px rgba(59, 26, 26, 0.79) !important;
  -webkit-box-shadow: -14px 15px 42px -8px -18px rgba(53, 24, 24, 0.79) !important;
  -moz-box-shadow: -14px 15px 42px -8px rgba(73, 34, 33, 0.79) !important;
}

.table-box-shadow {
  box-shadow: -24px -29px 104px -64px rgba(0, 186, 196, 0.79) !important;
  -webkit-box-shadow: -24px -29px 104px -64px rgba(0, 186, 196, 0.79) !important;
  -moz-box-shadow: -24px -29px 104px -64px rgba(0, 186, 196, 0.79) !important;
}

.card-border-top {
  border-top: 7px solid;
}

link {
  cursor: pointer !important;
}
.link:hover {
  opacity: 0.6 !important;
}
.scroll-top-light:hover {
  background-color: inherit !important;
  color: #ececec !important;
}

.scroll-top-dark:hover {
  background-color: #ffd91b !important;
  color: #ececec !important;
}

/** Custom Text Font-Family Classes **/

.font-1 {
  font-family: "Noto Sans", sans-serif !important;
}
.font-2 {
  font-family: "Montserrat Alternates", sans-serif !important;
}
.font-3 {
  font-family: "Fredericka the Great", cursive !important;
}
.font-4 {
  font-family: "Black Ops One", cursive;
}

/** ANIMATIONS **/
.close-text-animate:hover .icon-animate {
  animation: rotate 0.2s linear;
  color: #0099cc !important;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.close-text-animate:hover {
  color: #0099cc !important;
}

.bg {
  /* Full height */
  height: 100% !important;
  /* Center and scale the image nicely */
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  -webkit-filter: grayscale(10%) saturate(1.4);
  filter: grayscale(10%) saturate(1.5);
  transition: all 0.5s ease-in-out !important;
}

.bg-half {
  /* Full height */
  height: 100% !important;
  /* Center and scale the image nicely */
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  -webkit-filter: grayscale(10%) saturate(1.9);
  filter: grayscale(10%) saturate(1.9) !important;
  transition: 1s ease-in-out !important;
}

/**
 link hover underline
*/

.nav-link-none {
  position: relative !important;
  text-decoration: none !important;
  letter-spacing: 0.5px !important;
}

.nav-link-none:hover {
  color: #505050 !important;
}

.nav-link {
  position: relative !important;
  text-decoration: none !important;
  letter-spacing: 0.5px !important;
}

.nav-link:after {
  content: "" !important;
  position: absolute !important;
  background-color: #0099cc !important;
  height: 2px !important;
  width: 100% !important;
  left: 0 !important;
  bottom: 14px !important;
  transition: 0.3s !important;
}

.nav-link:hover:after {
  width: 100% !important;
}

.nav-link:hover {
  color: #0099cc !important;
}

/** empire **/

.nav-link-empire {
  position: relative !important;
  text-decoration: none !important;
  letter-spacing: 0.5px !important;
}

.nav-link-empire:after {
  content: "" !important;
  position: absolute !important;
  background-color: #26a69a !important;
  height: 2px !important;
  width: 100% !important;
  left: 0 !important;
  bottom: 14px !important;
  transition: 0.3s !important;
}

.nav-link-empire:hover:after {
  width: 100% !important;
}

.nav-link-empire:hover {
  color: #26a69a !important;
}

.empire-search-button:hover {
  background-color: #26a69a !important;
}

/** shop **/

.nav-link-shop {
  position: relative !important;
  text-decoration: none !important;
  letter-spacing: 0.5px !important;
}

.nav-link-shop:after {
  content: "" !important;
  position: absolute !important;
  background-color: #ff5722 !important;
  height: 2px !important;
  width: 100% !important;
  left: 0 !important;
  bottom: 14px !important;
  transition: 0.3s !important;
}

.nav-link-shop:hover:after {
  width: 100% !important;
}

.nav-link-shop:hover {
  color: #ff5722 !important;
}

.shop-search-button:hover {
  background-color: #ff5722 !important;
}

/** properties **/

.nav-link-properties {
  position: relative !important;
  text-decoration: none !important;
  letter-spacing: 0.5px !important;
}

.nav-link-properties:after {
  content: "" !important;
  position: absolute !important;
  background-color: #673ab7 !important;
  height: 2px !important;
  width: 100% !important;
  left: 0 !important;
  bottom: 14px !important;
  transition: 0.3s !important;
}

.nav-link-properties:hover:after {
  width: 100% !important;
}

.nav-link-properties:hover {
  color: #673ab7 !important;
}

.properties-search-button:hover {
  background-color: #673ab7 !important;
}

/** car **/

.nav-link-car {
  position: relative !important;
  text-decoration: none !important;
  letter-spacing: 0.5px !important;
}

.nav-link-car:after {
  content: "" !important;
  position: absolute !important;
  background-color: #c0ca33 !important;
  height: 2px !important;
  width: 100% !important;
  left: 0 !important;
  bottom: 14px !important;
  transition: 0.3s !important;
}

.nav-link-car:hover:after {
  width: 100% !important;
}

.nav-link-car:hover {
  color: #c0ca33 !important;
}

.car-search-button:hover {
  background-color: #c0ca33 !important;
}

/** blog **/

.nav-link-blog {
  position: relative !important;
  text-decoration: none !important;
  letter-spacing: 0.5px !important;
}

.nav-link-blog:after {
  content: "" !important;
  position: absolute !important;
  background-color: #b73a3a !important;
  height: 2px !important;
  width: 100% !important;
  left: 0 !important;
  bottom: 14px !important;
  transition: 0.3s !important;
}

.nav-link-blog:hover:after {
  width: 100% !important;
}

.nav-link-blog:hover {
  color: #b73a3a !important;
}

.blog-search-button:hover {
  background-color: #b73a3a !important;
}

/*-----------------------------*/

/*  home page image slider*/
.img-container {
  position: relative !important;
}
.img-overlay-video-icon {
  position: absolute;
  top: 0;
  width: 100%;
  transition: 0.5s ease;
  opacity: 1;
}

.img-overlay-text {
  position: absolute !important;
  color: #fff !important;
  transition: 0.5s ease !important;
  opacity: 1 !important;
}

.img-overlay-text-secondary {
  position: absolute !important;
  color: #fff !important;
  transition: 0.5s ease !important;
  opacity: 1 !important;
}

.img-overlay-description {
  position: absolute !important;
  color: #fff !important;
  transition: 0.5s ease !important;
  opacity: 1 !important;
}

.img-overlay-button {
  position: absolute !important;
  transition: 0.5s ease !important;
  opacity: 1 !important;
}

.img-overlay-prev-icon {
  position: absolute !important;
  top: 45% !important;
  width: 100% !important;
  transition: 0.5s ease !important;
  opacity: 1 !important;
  cursor: pointer !important;
  display: flex !important;
  justify-content: flex-start !important;
}

.img-overlay-next-icon {
  position: absolute !important;
  top: 45% !important;
  width: 100% !important;
  transition: 0.5s ease !important;
  opacity: 1 !important;
  cursor: pointer !important;
  display: flex !important;
  justify-content: flex-end !important;
}

.img-overlay-prev:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.img-overlay-next:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

/*------------------*/

.short-text {
  text-overflow: ellipsis !important;
}

/**  CSS STYLE FOR IMAGE SLIDE SHOWS  **/

* {
  box-sizing: border-box;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px !important;
  position: relative !important;
  margin: auto !important;
}

/* Hide the images by default */

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer !important;
  position: absolute !important;
  top: 40% !important;
  width: auto !important;
  margin-top: -22px !important;
  padding: 16px !important;
  color: white !important;
  font-weight: bold !important;
  font-size: 18px !important;
  transition: 0.6s ease !important;
  border-radius: 0 3px 3px 0 !important;
  user-select: none !important;
}

/* Position the "next button" to the right */
.next {
  right: 0 !important;
  border-radius: 3px 0 0 2px !important;
}

/* On hover, add a black b4ckground color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.host-hover:hover {
  background-color: #006b8e !important;
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer !important;
  height: 15px !important;
  width: 15px !important;
  margin: 0 2px !important;
  background-color: #bbb !important;
  border-radius: 50% !important;
  display: inline-block !important;
  transition: background-color 0.6s ease !important;
}

.active,
.dot:hover {
  background-color: #0099cc !important;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.bgimg-1,
.bgimg-2,
.bgimg-3 {
  position: relative;
  opacity: 0.9;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bgimg-cover {
  min-height: 400px;
  position: relative;
  opacity: 0.9;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: saturate(80%);
}
.img-undo-effect {
  filter: grayscale(0%) blur(0px);
  position: absolute;
}

.bgimg-1 {
  min-height: 400px;
}

.bgimg-2 {
  filter: saturate(85%) !important;
  min-height: 440px;
  z-index: 3 !important;
}

.bgimg-3 {
  z-index: 1 !important;
  min-height: 440px !important;
}

.caption {
  position: absolute !important;
  left: 0 !important;
  top: 40% !important;
  width: 100% !important;
  text-align: center !important;
  color: #000 !important;
}

.caption span.border {
  background-color: #ffffff !important;
  border-radius: 3px !important;
  color: #242424 !important;
  padding: 10px !important;
  font-size: 20px !important;
  letter-spacing: 1px !important;
  font-weight: 600 !important;
}

.img-caption {
  position: absolute !important;
  left: 0 !important;
  top: 40% !important;
  width: 100% !important;
  text-align: center !important;
  color: #000 !important;
}

span.border:hover {
  background-color: rgb(15, 54, 81) !important;
  color: #ffffff !important;
}

.img-caption span.img-border {
  background-color: #ffffff !important;
  border-radius: 3px !important;
  color: #242424 !important;
  padding: 10px !important;
  font-size: 20px !important;
  letter-spacing: 1px !important;
  font-weight: 600 !important;
}

span.img-border:hover {
  background-color: rgb(15, 54, 81) !important;
  color: #ffffff !important;
}

.card-container {
  position: relative !important;
  cursor: pointer !important;
}

.card-text {
  position: absolute !important;
  top: 0% !important;
}

.card-container:hover .img-card {
  opacity: 0.9 !important;
  cursor: pointer !important;
}

.card-container:hover .card-text {
  background: linear-gradient(
    90deg,
    rgb(8, 15, 23) 0%,
    rgb(14, 18, 23) 39%,
    rgb(14, 35, 50) 100%
  );
  width: auto !important;
  height: 100% !important;
  opacity: 0.8 !important;
  top: 0% !important;
}

.card-container:hover .view-project {
  display: flex !important;
  animation: scale 0.3s ease-in !important;
}

.card-container:hover .card-text-child {
  display: flex !important;
  animation: scale 0.3s ease-in !important;
}

.card-container:hover .card-text-title {
  display: flex !important;
  animation: scale 0.3s ease-in !important;
}

@keyframes scale {
  0% {
    transform: scaleY(0%);
  }

  100% {
    transform: scaleY(100%);
  }
}

.card-container2 {
  position: relative !important;
  cursor: pointer !important;
}

.card-text2 {
  position: absolute !important;
  top: 0% !important;
}

.card-container2 {
  background: linear-gradient(
    90deg,
    rgb(8, 15, 23) 0%,
    rgb(14, 18, 23) 39%,
    rgb(14, 35, 50) 100%
  );
  width: auto !important;
  height: 100% !important;
  opacity: 0.8 !important;
  top: 0% !important;
}

.client-icon-button:hover {
  background-color: #0f1211 !important;
  opacity: 0.9 !important;
}

.client-icon-button:hover .client-text {
  color: #ffffff !important;
}

.landing-button:hover {
  background: #0099cc !important;
}

.logo-text {
  font-family: "Courier New", Courier, monospace !important;
  font-weight: 900 !important;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.buttons {
  margin: 10%;
  text-align: center;
}

.btn-hover {
  width: 200px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  height: 55px;
  text-align: center;
  border: none;
  background-size: 300% 100%;

  border-radius: 50px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0 !important;
  moz-transition: all 0.4s ease-in-out !important;
  -o-transition: all 0.4s ease-in-out !important;
  -webkit-transition: all 0.4s ease-in-out !important;
  transition: all 0.4s ease-in-out !important;
}

.btn-hover:focus {
  outline: none !important;
}

.btn-hover.color-1 {
  background-image: linear-gradient(
    to right,
    #25aae1,
    #40e495,
    #30dd8a,
    #2bb673
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75) !important;
}
.btn-hover.color-2 {
  background-image: linear-gradient(
    to right,
    #f5ce62,
    #e43603,
    #fa7199,
    #e85a19
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(229, 66, 10, 0.75) !important;
}
.btn-hover.color-3 {
  background-image: linear-gradient(
    to right,
    #667eea,
    #764ba2,
    #6b8dd6,
    #8e37d7
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75) !important;
}
.btn-hover.color-4 {
  background-image: linear-gradient(
    to right,
    #fc6076,
    #ff9a44,
    #ef9d43,
    #e75516
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(252, 104, 110, 0.75) !important;
}
.btn-hover.color-5 {
  background-image: linear-gradient(
    to right,
    #0ba360,
    #3cba92,
    #30dd8a,
    #2bb673
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75) !important;
}
.btn-hover.color-6 {
  background-image: linear-gradient(
    to right,
    #009245,
    #fcee21,
    #00a8c5,
    #d9e021
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(83, 176, 57, 0.75) !important;
}
.btn-hover.color-7 {
  background-image: linear-gradient(
    to right,
    #6253e1,
    #852d91,
    #a3a1ff,
    #f24645
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(126, 52, 161, 0.75) !important;
}
.btn-hover.color-8 {
  background-image: linear-gradient(
    to right,
    #29323c,
    #485563,
    #2b5876,
    #4e4376
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75) !important;
}
.btn-hover.color-9 {
  background-image: linear-gradient(
    to right,
    #25aae1,
    #4481eb,
    #04befe,
    #3f86ed
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75) !important;
}
.btn-hover.color-10 {
  background-image: linear-gradient(
    to right,
    #ed6ea0,
    #ec8c69,
    #f7186a,
    #fbb03b
  ) !important;
  box-shadow: 0 4px 15px 0 rgba(236, 116, 149, 0.75) !important;
}
.btn-hover.color-11 {
  background-image: linear-gradient(
    to right,
    #eb3941,
    #f15e64,
    #e14e53,
    #e2373f
  ) !important;
  box-shadow: 0 5px 15px rgba(242, 97, 103, 0.4) !important;
}
/** **/
.img-effect-secondary {
  -webkit-filter: blur(1px) grayscale(10%) saturate(1.4);
  filter: saturate(1.9);
  transition: 0.5s ease;
}

.image-effect-main {
  -webkit-filter: blur(10px) grayscale(80%);
  filter: blur(10px) grayscale(80%);
}

.img-container {
  position: relative;
}
.img-overlay-video-icon {
  position: absolute;
  top: 0;
  width: 100%;
  transition: 0.5s ease;
  opacity: 1;
}

.img-overlay-text {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.8); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 1;
}

.img-overlay-text-secondary {
  position: absolute;
  bottom: 0;
  color: #f1f1f1;
  width: 90%;
  transition: 0.5s ease;
  opacity: 1;
}

.img-overlay-prev-icon {
  position: absolute !important;
  top: 40% !important;
  width: 100% !important;
  transition: 0.5s ease !important;
  opacity: 1 !important;
  cursor: pointer !important;
  display: flex !important;
  justify-content: flex-start !important;
}

.img-hover-zoom:hover {
  transform: scale(1.05) !important;
  cursor: pointer !important;
  transition: 0.3s ease !important;
}

.search-input-hover:hover {
  background-color: rgb(219, 219, 219) !important;
}

.swiper {
  width: 100% !important;
  height: 100% !important;
}

.swiper-slide {
  text-align: center !important;
  font-size: 18px !important;
  background: rgb(82, 53, 53) !important;

  /* Center slide text vertically */
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center !important;
  align-items: center !important;
}

.swiper-slide img {
  display: block !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

.img-hover-zoom {
  height: 237px !important; /* Modify this according to your need */
  overflow: hidden !important; /* Removing this will break the effects */
}

/* Zoom-n-rotate Container */
.img-hover-zoom--zoom-n-rotate .card-img {
  transition: transform 0.5s ease !important;
}

/* The Transformation */
.img-hover-zoom--zoom-n-rotate:hover .card-img {
  transform: scale(1.2) !important;
}

.empire-btn-hover:hover {
  background-color: #1d97b0 !important;
  color: #ffffff !important;
}

.shop-btn-hover:hover {
  background-color: #e64a19 !important;
  color: #ffffff !important;
}

.properties-btn-hover:hover {
  background-color: #5e35b1 !important;
  color: #ffffff !important;
}

.car-btn-hover:hover {
  background-color: #689f38 !important;
  color: #ffffff !important;
}

.blog-btn-hover:hover {
  background-color: #e53935 !important;
  color: #ffffff !important;
}

.secondary-btn-hover:hover {
  background-color: #e65100 !important;
  color: #ffffff !important;
}

.top-container {
  position: relative !important;
  width: 90% !important;
}

.top-image {
  opacity: 1 !important;
  display: block !important;
  height: 340px !important;
  transition: 0.5s ease !important;
  backface-visibility: hidden !important;
}

.top-middle {
  transition: 0.5s ease !important;
  opacity: 0 !important;
  position: absolute !important;
  bottom: 60% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  text-align: center !important;
  width: 90% !important;
}
.top-main {
  transition: 0.5s ease !important;
  opacity: 0 !important;
  position: absolute !important;
  top: 80% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  text-align: center !important;
  width: 90% !important;
}

.top-container:hover .top-image {
  opacity: 1 !important;
}

.top-container:hover .top-middle,
.top-main {
  opacity: 1 !important;
}

.top-text {
  background-color: #137ab1 !important;
  color: white !important;
  padding: 16px 32px !important;
}

.top-main-text {
  background-color: #ffffff !important;
  color: rgb(0, 0, 0) !important;
  padding: 16px 32px !important;
}

.top-img-hover-zoom {
  overflow: hidden !important; /* Removing this will break the effects */
}

/* Zoom-n-rotate Container */
.top-img-hover-zoom--zoom-n-rotate .top-card-img {
  transition: transform 0.5s ease !important;
}

/* The Transformation */
.top-img-hover-zoom--zoom-n-rotate:hover .top-card-img {
  transform: scale(1.2) !important;
}

/**
 TEXT EDITOR (QUILL0
**/

.editor {
  margin-top: 10px;
}

.editor .ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

/* Snow Theme */
.editor .ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

/* Bubble Theme */
.editor .ql-bubble .ql-editor {
  border: 1px solid #ccc;
  border-radius: 0.5em;
}

.editor .ql-editor {
  min-height: 29em;
}

.editor2 {
  margin-top: 10px;
}

.editor2 .ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

/* Snow Theme */
.editor2 .ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

/* Bubble Theme */
.editor2 .ql-bubble .ql-editor {
  border: 1px solid #ccc;
  border-radius: 0.5em;
}

.editor2 .ql-editor {
  min-height: 15em;
}

.themeSwitcher {
  margin-top: 0.5em;
  font-size: small;
}

.expand-empire-overview {
  color: #04a275 !important;
}

.expand-empire-overview-content {
  color: #4c4c4c !important;
  font-weight: lighter !important;
}

.expand-attraction-overview {
  color: #cb4a12 !important;
}

.public-background {
  background: -webkit-linear-gradient(70deg, #110740 30%, rgba(0, 0, 0, 0) 30%),
    -webkit-linear-gradient(30deg, #0c052e 60%, #080320 60%) !important;
  background: -o-linear-gradient(70deg, #110740 30%, rgba(0, 0, 0, 0) 30%),
    -o-linear-gradient(30deg, #0c052e 60%, #080320 60%) !important;
  background: -moz-linear-gradient(70deg, #110740 30%, rgba(0, 0, 0, 0) 30%),
    -moz-linear-gradient(30deg, #0c052e 60%, #080320 60%) !important;
  background: linear-gradient(70deg, #110740 30%, rgba(0, 0, 0, 0) 30%),
    linear-gradient(30deg, #0c052e 60%, #080320 60%) !important;
}

/**
 TEXT EDITOR (QUILL0
**/

.editor {
  margin-top: 10px;
}

.editor .ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

/* Snow Theme */
.editor .ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

/* Bubble Theme */
.editor .ql-bubble .ql-editor {
  border: 1px solid #ccc;
  border-radius: 0.5em;
}

.editor .ql-editor {
  min-height: 29em;
}

.editor2 {
  margin-top: 10px;
}

.editor2 .ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

/* Snow Theme */
.editor2 .ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

/* Bubble Theme */
.editor2 .ql-bubble .ql-editor {
  border: 1px solid #ccc;
  border-radius: 0.5em;
}

.editor2 .ql-editor {
  min-height: 25em;
}

.themeSwitcher {
  margin-top: 0.5em;
  font-size: small;
}

.footer-background {
  background: -webkit-linear-gradient(70deg, #110740 30%, rgba(0, 0, 0, 0) 30%),
    -webkit-linear-gradient(30deg, #0c052e 60%, #080320 60%) !important;
  background: -o-linear-gradient(70deg, #110740 30%, rgba(0, 0, 0, 0) 30%),
    -o-linear-gradient(30deg, #0c052e 60%, #080320 60%) !important;
  background: -moz-linear-gradient(70deg, #110740 30%, rgba(0, 0, 0, 0) 30%),
    -moz-linear-gradient(30deg, #0c052e 60%, #080320 60%) !important;
  background: linear-gradient(70deg, #110740 30%, rgba(0, 0, 0, 0) 30%),
    linear-gradient(30deg, #0c052e 60%, #080320 60%) !important;
}
